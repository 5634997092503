/// <reference types="vite/client" />
import { z } from 'zod';

declare global {
  interface ImportMetaEnv extends Environment {}
}

const envSchema = z.object({
  VITE_STORAGE_NAME: z.string(),
  VITE_API_URL: z.string().url(),
  VITE_POSTHOG_API_KEY: z.string(),
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_AUDIENCE: z.string().url(),
  VITE_ENV: z
    .enum(['development', 'staging', 'production'])
    .default('development'),
  IS_RUNNING_IN_LOCALHOST: z.boolean().default(false),
});

type Environment = z.infer<typeof envSchema>;

function validateEnv(): Environment {
  try {
    const isRunningInLocalhost = import.meta.env.DEV;

    const parsedEnv = envSchema.parse({
      ...import.meta.env,
      IS_RUNNING_IN_LOCALHOST: isRunningInLocalhost,
    });

    return parsedEnv;
  } catch (error) {
    console.error('❌ Invalid environment variables', error);
    throw new Error('Invalid environment configuration');
  }
}

export function getEnvVar<K extends keyof Environment>(key: K): Environment[K] {
  return env[key];
}

const env = validateEnv();

export default env;
