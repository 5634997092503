import { createFileRoute, redirect } from '@tanstack/react-router';

import { isAuthenticated } from '@/utils/auth';

export const Route = createFileRoute('/login')({
  beforeLoad: ({ location }) => {
    if (isAuthenticated()) {
      throw redirect({
        to: new URLSearchParams(location.search).get('redirect') || '/',
        replace: true,
      });
    }
  },
});
