import { Outlet } from '@tanstack/react-router';

import { useAppStore } from '../store';

import Toast from './blocks/Toast';
import Splash from './ui/Splash';

import { useUpdate } from '@/providers/UpdateProvider';
import UpdateScreen from '@/screens/UpdateScreen';

export const RouterRoot = () => {
  const { updateAvailable } = useUpdate();

  const { _hasHydrated } = useAppStore((state) => state);

  if (!_hasHydrated) return <Splash />;

  if (updateAvailable) return <UpdateScreen />;

  return (
    <>
      <Outlet />
      <Toast />
    </>
  );
};
