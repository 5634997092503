import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { onboardingQueryOptions } from '@/query/onboarding';
import { checkAccessibilityPermissionsQueryOptions } from '@/query/permissions';
import { userQueryOptions } from '@/query/user';
import { STEP_INITIAL_COMPLETE } from '@/stores/atoms/onboarding';
import { queryClient } from '@/stores/jotaiStore';
import { isAuthenticated } from '@/utils/auth';
import { inDesktop } from '@/utils/platform';

export const Route = createFileRoute('/')({
  beforeLoad: async ({ location }) => {
    if (!isAuthenticated())
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });

    if (inDesktop()) {
      const hasAccessibilityPermissions = await queryClient.fetchQuery(
        checkAccessibilityPermissionsQueryOptions()
      );

      if (!hasAccessibilityPermissions)
        throw redirect({
          to: '/grant-permissions',
          replace: true,
        });
    }

    const { onboarding_progress } = await queryClient.ensureQueryData(
      onboardingQueryOptions()
    );

    if (
      onboarding_progress < STEP_INITIAL_COMPLETE &&
      posthog.isFeatureEnabled('state-aware-onboarding')
    )
      throw redirect({
        to: '/onboarding',
      });
  },
  loader: async () => {
    const getUser = () => queryClient.ensureQueryData(userQueryOptions());

    return {
      deferredUser: getUser(),
    };
  },
});
