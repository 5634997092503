/* eslint-disable no-console */
import { warn, debug, trace, info, error } from '@tauri-apps/plugin-log';

import { inBrowser } from './platform';

import { inMobile } from '@/utils/platform';

export function setupLogger() {
  if (inBrowser() || inMobile()) return;
  function forwardConsole(
    fnName: 'log' | 'debug' | 'info' | 'warn' | 'error',
    logger: (message: string) => Promise<void>
  ) {
    const original = console[fnName];
    console[fnName] = (message) => {
      original(message);
      logger(message);
    };
  }

  forwardConsole('log', trace);
  forwardConsole('debug', debug);
  forwardConsole('info', info);
  forwardConsole('warn', warn);
  forwardConsole('error', error);
}
