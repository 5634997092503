import { useCallback, useMemo } from 'react';

import { TokenExpiredError, UnableToConnectToServerError } from '@/error';
import { router } from '@/router';

export function useError(error: Error) {
  const title = useMemo(() => {
    if (error instanceof TokenExpiredError) {
      return 'Session expired.';
    }

    if (error instanceof UnableToConnectToServerError) {
      return 'Cannot connect to server.';
    }

    return 'Something went wrong.';
  }, [error]);

  const message = useMemo(() => {
    if (error instanceof TokenExpiredError) {
      return 'Your session has expired. Please login again.';
    }

    if (error instanceof UnableToConnectToServerError) {
      return 'Please wait a moment and try again.';
    }

    if (error instanceof Error) {
      return error.message;
    }

    return JSON.stringify(error);
  }, [error]);

  const actionLabel = useMemo(() => {
    if (error instanceof TokenExpiredError) {
      return 'Login';
    }

    if (error instanceof UnableToConnectToServerError) {
      return 'Retry';
    }

    return 'Reload';
  }, [error]);

  const action = useCallback(() => {
    if (error instanceof TokenExpiredError) {
      router.navigate({ to: '/login' });

      return;
    }

    if (error instanceof UnableToConnectToServerError) {
      router.navigate({ to: '/' });

      return;
    }

    window.location.reload();
  }, [error]);

  return {
    title,
    message,
    action,
    actionLabel,
  };
}
