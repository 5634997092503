/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PersonalizeImport } from './routes/personalize'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as LoginImport } from './routes/login'
import { Route as GrantPermissionsImport } from './routes/grant-permissions'
import { Route as IndexImport } from './routes/index'
import { Route as ErrorSessionExpiredImport } from './routes/error/session-expired'
import { Route as ErrorFailedToConnectImport } from './routes/error/failed-to-connect'

// Create Virtual Routes

const UpdateLazyImport = createFileRoute('/update')()
const SpotlightLazyImport = createFileRoute('/spotlight')()
const MiniChatLazyImport = createFileRoute('/mini-chat')()
const SharedChatIdLazyImport = createFileRoute('/shared-chat/$id')()
const AccountDeleteLazyImport = createFileRoute('/account/delete')()

// Create/Update Routes

const UpdateLazyRoute = UpdateLazyImport.update({
  id: '/update',
  path: '/update',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/update.lazy').then((d) => d.Route))

const SpotlightLazyRoute = SpotlightLazyImport.update({
  id: '/spotlight',
  path: '/spotlight',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/spotlight.lazy').then((d) => d.Route))

const MiniChatLazyRoute = MiniChatLazyImport.update({
  id: '/mini-chat',
  path: '/mini-chat',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/mini-chat.lazy').then((d) => d.Route))

const PersonalizeRoute = PersonalizeImport.update({
  id: '/personalize',
  path: '/personalize',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/personalize.lazy').then((d) => d.Route))

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/onboarding.lazy').then((d) => d.Route))

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const GrantPermissionsRoute = GrantPermissionsImport.update({
  id: '/grant-permissions',
  path: '/grant-permissions',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/grant-permissions.lazy').then((d) => d.Route),
)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const SharedChatIdLazyRoute = SharedChatIdLazyImport.update({
  id: '/shared-chat/$id',
  path: '/shared-chat/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/shared-chat.$id.lazy').then((d) => d.Route),
)

const AccountDeleteLazyRoute = AccountDeleteLazyImport.update({
  id: '/account/delete',
  path: '/account/delete',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/account/delete.lazy').then((d) => d.Route),
)

const ErrorSessionExpiredRoute = ErrorSessionExpiredImport.update({
  id: '/error/session-expired',
  path: '/error/session-expired',
  getParentRoute: () => rootRoute,
} as any)

const ErrorFailedToConnectRoute = ErrorFailedToConnectImport.update({
  id: '/error/failed-to-connect',
  path: '/error/failed-to-connect',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/grant-permissions': {
      id: '/grant-permissions'
      path: '/grant-permissions'
      fullPath: '/grant-permissions'
      preLoaderRoute: typeof GrantPermissionsImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/personalize': {
      id: '/personalize'
      path: '/personalize'
      fullPath: '/personalize'
      preLoaderRoute: typeof PersonalizeImport
      parentRoute: typeof rootRoute
    }
    '/mini-chat': {
      id: '/mini-chat'
      path: '/mini-chat'
      fullPath: '/mini-chat'
      preLoaderRoute: typeof MiniChatLazyImport
      parentRoute: typeof rootRoute
    }
    '/spotlight': {
      id: '/spotlight'
      path: '/spotlight'
      fullPath: '/spotlight'
      preLoaderRoute: typeof SpotlightLazyImport
      parentRoute: typeof rootRoute
    }
    '/update': {
      id: '/update'
      path: '/update'
      fullPath: '/update'
      preLoaderRoute: typeof UpdateLazyImport
      parentRoute: typeof rootRoute
    }
    '/error/failed-to-connect': {
      id: '/error/failed-to-connect'
      path: '/error/failed-to-connect'
      fullPath: '/error/failed-to-connect'
      preLoaderRoute: typeof ErrorFailedToConnectImport
      parentRoute: typeof rootRoute
    }
    '/error/session-expired': {
      id: '/error/session-expired'
      path: '/error/session-expired'
      fullPath: '/error/session-expired'
      preLoaderRoute: typeof ErrorSessionExpiredImport
      parentRoute: typeof rootRoute
    }
    '/account/delete': {
      id: '/account/delete'
      path: '/account/delete'
      fullPath: '/account/delete'
      preLoaderRoute: typeof AccountDeleteLazyImport
      parentRoute: typeof rootRoute
    }
    '/shared-chat/$id': {
      id: '/shared-chat/$id'
      path: '/shared-chat/$id'
      fullPath: '/shared-chat/$id'
      preLoaderRoute: typeof SharedChatIdLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/grant-permissions': typeof GrantPermissionsRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/personalize': typeof PersonalizeRoute
  '/mini-chat': typeof MiniChatLazyRoute
  '/spotlight': typeof SpotlightLazyRoute
  '/update': typeof UpdateLazyRoute
  '/error/failed-to-connect': typeof ErrorFailedToConnectRoute
  '/error/session-expired': typeof ErrorSessionExpiredRoute
  '/account/delete': typeof AccountDeleteLazyRoute
  '/shared-chat/$id': typeof SharedChatIdLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/grant-permissions': typeof GrantPermissionsRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/personalize': typeof PersonalizeRoute
  '/mini-chat': typeof MiniChatLazyRoute
  '/spotlight': typeof SpotlightLazyRoute
  '/update': typeof UpdateLazyRoute
  '/error/failed-to-connect': typeof ErrorFailedToConnectRoute
  '/error/session-expired': typeof ErrorSessionExpiredRoute
  '/account/delete': typeof AccountDeleteLazyRoute
  '/shared-chat/$id': typeof SharedChatIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/grant-permissions': typeof GrantPermissionsRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/personalize': typeof PersonalizeRoute
  '/mini-chat': typeof MiniChatLazyRoute
  '/spotlight': typeof SpotlightLazyRoute
  '/update': typeof UpdateLazyRoute
  '/error/failed-to-connect': typeof ErrorFailedToConnectRoute
  '/error/session-expired': typeof ErrorSessionExpiredRoute
  '/account/delete': typeof AccountDeleteLazyRoute
  '/shared-chat/$id': typeof SharedChatIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/grant-permissions'
    | '/login'
    | '/onboarding'
    | '/personalize'
    | '/mini-chat'
    | '/spotlight'
    | '/update'
    | '/error/failed-to-connect'
    | '/error/session-expired'
    | '/account/delete'
    | '/shared-chat/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/grant-permissions'
    | '/login'
    | '/onboarding'
    | '/personalize'
    | '/mini-chat'
    | '/spotlight'
    | '/update'
    | '/error/failed-to-connect'
    | '/error/session-expired'
    | '/account/delete'
    | '/shared-chat/$id'
  id:
    | '__root__'
    | '/'
    | '/grant-permissions'
    | '/login'
    | '/onboarding'
    | '/personalize'
    | '/mini-chat'
    | '/spotlight'
    | '/update'
    | '/error/failed-to-connect'
    | '/error/session-expired'
    | '/account/delete'
    | '/shared-chat/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  GrantPermissionsRoute: typeof GrantPermissionsRoute
  LoginRoute: typeof LoginRoute
  OnboardingRoute: typeof OnboardingRoute
  PersonalizeRoute: typeof PersonalizeRoute
  MiniChatLazyRoute: typeof MiniChatLazyRoute
  SpotlightLazyRoute: typeof SpotlightLazyRoute
  UpdateLazyRoute: typeof UpdateLazyRoute
  ErrorFailedToConnectRoute: typeof ErrorFailedToConnectRoute
  ErrorSessionExpiredRoute: typeof ErrorSessionExpiredRoute
  AccountDeleteLazyRoute: typeof AccountDeleteLazyRoute
  SharedChatIdLazyRoute: typeof SharedChatIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  GrantPermissionsRoute: GrantPermissionsRoute,
  LoginRoute: LoginRoute,
  OnboardingRoute: OnboardingRoute,
  PersonalizeRoute: PersonalizeRoute,
  MiniChatLazyRoute: MiniChatLazyRoute,
  SpotlightLazyRoute: SpotlightLazyRoute,
  UpdateLazyRoute: UpdateLazyRoute,
  ErrorFailedToConnectRoute: ErrorFailedToConnectRoute,
  ErrorSessionExpiredRoute: ErrorSessionExpiredRoute,
  AccountDeleteLazyRoute: AccountDeleteLazyRoute,
  SharedChatIdLazyRoute: SharedChatIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/grant-permissions",
        "/login",
        "/onboarding",
        "/personalize",
        "/mini-chat",
        "/spotlight",
        "/update",
        "/error/failed-to-connect",
        "/error/session-expired",
        "/account/delete",
        "/shared-chat/$id"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/grant-permissions": {
      "filePath": "grant-permissions.ts"
    },
    "/login": {
      "filePath": "login.ts"
    },
    "/onboarding": {
      "filePath": "onboarding.ts"
    },
    "/personalize": {
      "filePath": "personalize.ts"
    },
    "/mini-chat": {
      "filePath": "mini-chat.lazy.tsx"
    },
    "/spotlight": {
      "filePath": "spotlight.lazy.tsx"
    },
    "/update": {
      "filePath": "update.lazy.tsx"
    },
    "/error/failed-to-connect": {
      "filePath": "error/failed-to-connect.ts"
    },
    "/error/session-expired": {
      "filePath": "error/session-expired.ts"
    },
    "/account/delete": {
      "filePath": "account/delete.lazy.tsx"
    },
    "/shared-chat/$id": {
      "filePath": "shared-chat.$id.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
