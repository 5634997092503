import { Category } from '@/types/dataclasses';

export const fakeCategories: Category[] = [
  {
    id: 1,
    name: 'Family',
    capture: 'capture 1',
    ignore: 'ignore 1',
  },
  {
    id: 2,
    name: 'Health',
    capture: 'capture 2',
    ignore: 'ignore 2',
  },
  {
    id: 3,
    name: 'Work',
    capture: 'capture 3',
    ignore: 'ignore 3',
  },
  {
    id: 4,
    name: 'Personal',
    capture: 'capture 4',
    ignore: 'ignore 4',
  },
  {
    id: 5,
    name: 'Education',
    capture: 'capture 5',
    ignore: 'ignore 5',
  },
  {
    id: 6,
    name: 'Hobbies',
    capture: 'capture 6',
    ignore: 'ignore 6',
  },
  {
    id: 7,
    name: 'Finance',
    capture: 'capture 7',
    ignore: 'ignore 7',
  },
  {
    id: 8,
    name: 'Travel',
    capture: 'capture 8',
    ignore: 'ignore 8',
  },
  {
    id: 9,
    name: 'Home',
    capture: 'capture 9',
    ignore: 'ignore 9',
  },
  {
    id: 10,
    name: 'Social',
    capture: 'capture 10',
    ignore: 'ignore 10',
  },
  {
    id: 11,
    name: 'Projects',
    capture: 'capture 11',
    ignore: 'ignore 11',
  },
  {
    id: 14,
    name: 'Volunteering',
    capture: 'capture 14',
    ignore: 'ignore 14',
  },
];
