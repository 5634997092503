import { createFileRoute, redirect } from '@tanstack/react-router';

import { userQueryOptions } from '@/query/user';
import { queryClient } from '@/stores/jotaiStore';
import { isAuthenticated } from '@/utils/auth';

export const Route = createFileRoute('/personalize')({
  beforeLoad: () => {
    if (!isAuthenticated()) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  loader: () => {
    const getUser = async () => {
      try {
        return await queryClient.ensureQueryData(userQueryOptions());
      } catch (_error) {
        return null;
      }
    };

    return {
      deferredUser: getUser(),
    };
  },
});
