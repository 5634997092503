import { Todo, TodoStatus } from '@/types/dataclasses';

export const fakeTodos: Todo[] = [
  {
    id: 1,
    title: '(title) Practice juggling flaming torches',
    description: '(desc) Practice juggling flaming torches safely',
    status: TodoStatus.suggestion,
    date: 'Mon Jan 08 2025 09:15:00 GMT-0800',
    categories: ['Personal', 'Health'],
    order: 1,
  },
  {
    id: 2,
    title: '(title) Write quarterly report for robot overlords',
    description:
      '(desc) Write quarterly report for robot overlords and include detailed analysis of human inefficiencies, incorporating machine learning metrics and AI-driven productivity suggestions while maintaining appropriate levels of human deference to our future mechanical masters',
    status: TodoStatus.accepted,
    date: 'Mon Jan 09 2025 14:30:00 GMT-0800',
    categories: [],
    order: 2,
  },
  {
    id: 3,
    title: '(title) Day 47 of teaching cat to fetch',
    description: '(desc) Day 47 of teaching cat to fetch',
    status: TodoStatus.completed,
    date: 'Fri Feb 28 2025 10:45:00 GMT-0800',
    categories: ['Family', 'Personal'],
    order: 3,
  },
  {
    id: 4,
    title: '(title) Organize sock drawer by emotional significance',
    description:
      '(desc) Organize sock drawer by emotional significance and create detailed spreadsheet of sentimental value',
    status: TodoStatus.deleted,
    date: 'Sat Mar 01 2025 13:20:00 GMT-0800',
    categories: ['Personal'],
    order: 4,
  },
  {
    id: 5,
    title: '(title) Schedule dentist appointment',
    description:
      '(desc) Schedule dentist appointment and prepare list of excuses for not flossing regularly, including detailed timeline of water pressure issues, temporary hand paralysis, and that one time the dental floss was mysteriously replaced with cotton candy',
    status: TodoStatus.accepted,
    date: 'Tue Jan 09 2025 11:30:00 GMT-0800',
    categories: ['Health'],
    order: 5,
  },
  {
    id: 6,
    title: '(title) Build time machine out of cardboard boxes',
    description:
      '(desc) Build time machine out of cardboard boxes and convince neighborhood kids it actually works',
    status: TodoStatus.rejected,
    date: 'Mon Mar 10 2025 15:45:00 GMT-0800',
    categories: ['Personal'],
    order: 6,
  },
  {
    id: 7,
    title: '(title) Review project deadlines and plan for coffee crisis',
    description: '(desc) Review project deadlines and plan for coffee crisis',
    status: TodoStatus.completed,
    date: 'Wed Mar 05 2025 09:30:00 GMT-0800',
    categories: ['Work'],
    order: 7,
  },
  {
    id: 8,
    title: '(title) Plant victory garden of rubber ducks',
    description:
      '(desc) Plant victory garden of rubber ducks and establish proper watering schedule for optimal squeakiness',
    status: TodoStatus.suggestion,
    date: 'Mon Jan 07 2025 11:45:00 GMT-0800',
    categories: [],
    order: 8,
  },
  {
    id: 9,
    title: '(title) Weekly team sync meeting',
    description:
      '(desc) Weekly team sync meeting to discuss important matters and debate proper pronunciation of GIF, including historical analysis of creator intent, regional variations, and philosophical implications for human-computer interaction paradigms',
    status: TodoStatus.accepted,
    date: 'Tue Mar 18 2025 14:15:00 GMT-0800',
    categories: ['Work'],
    order: 9,
  },
  {
    id: 10,
    title: '(title) Meditate for 10 minutes',
    description:
      '(desc) Meditate for 10 minutes while trying not to think about that embarrassing thing from high school',
    status: TodoStatus.completed,
    date: 'Wed Mar 12 2025 16:40:00 GMT-0800',
    categories: ['Health', 'Personal'],
    order: 10,
  },
  {
    id: 11,
    title: '(title) Train for underwater basket weaving competition',
    description:
      '(desc) Train for underwater basket weaving competition and develop revolutionary new technique using snorkel',
    status: TodoStatus.suggestion,
    date: 'Thu May 01 2025 10:20:00 GMT-0800',
    categories: ['Personal', 'Health'],
    order: 11,
  },
  {
    id: 12,
    title: '(title) Family game night',
    description:
      '(desc) Family game night - No Monopoly this time! Last time someone tried to pay rent with cryptocurrency',
    status: TodoStatus.accepted,
    date: '2025-03-22',
    categories: ['Family'],
    order: 12,
  },
  {
    id: 13,
    title: '(title) Optimize coffee brewing process',
    description: '(desc) Optimize coffee brewing process',
    status: TodoStatus.completed,
    date: 'Sat Mar 08 2025 09:45:00 GMT-0800',
    categories: ['Personal', 'Work'],
    order: 13,
  },
  {
    id: 14,
    title: '(title) Learn to speak Klingon',
    description:
      '(desc) Learn to speak Klingon and practice pronunciation without scaring the neighbors or their pets',
    status: TodoStatus.deleted,
    date: 'Thu Feb 15 2025 16:20:00 GMT-0800',
    categories: ['Personal'],
    order: 14,
  },
  {
    id: 15,
    title: '(title) Annual health checkup',
    description:
      '(desc) Annual health checkup and prepare detailed list of WebMD self-diagnoses for doctor review',
    status: TodoStatus.suggestion,
    date: 'Tue Apr 15 2025 11:15:00 GMT-0800',
    categories: ['Health'],
    order: 15,
  },
  {
    id: 16,
    title: '(title) Debug production issue',
    description:
      '(desc) Debug production issue: computers only work upside down and monitors must be balanced on coffee cups, requiring complete reorganization of office furniture and gravity-defying monitor mounting solutions while maintaining OSHA compliance',
    status: TodoStatus.accepted,
    date: 'Wed Mar 19 2025 13:50:00 GMT-0800',
    categories: ['Work'],
    order: 16,
  },
  {
    id: 17,
    title: '(title) Take kids to dinosaur museum',
    description:
      '(desc) Take kids to dinosaur museum and try not to correct the tour guide about velociraptor facts',
    status: TodoStatus.completed,
    date: 'Sun Mar 09 2025 12:25:00 GMT-0800',
    categories: ['Family'],
    order: 17,
  },
  {
    id: 18,
    title: '(title) Start writing novel about coding pirates',
    description:
      '(desc) Start writing novel about coding pirates who debug their way through the seven digital seas',
    status: TodoStatus.rejected,
    date: 'Sun Mar 02 2025 14:55:00 GMT-0800',
    categories: ['Personal', 'Work'],
    order: 18,
  },
  {
    id: 19,
    title: '(title) Gym session',
    description:
      '(desc) Gym session: Learn to lift feathers and gradually work up to lifting cotton balls by end of month',
    status: TodoStatus.suggestion,
    date: 'Fri Mar 21 2025 10:05:00 GMT-0800',
    categories: ['Health'],
    order: 19,
  },
  {
    id: 20,
    title: '(title) Reorganize cloud storage',
    description:
      '(desc) Reorganize cloud storage by cloud type and create special folder for cumulonimbus-shaped files',
    status: TodoStatus.accepted,
    date: 'Thu Mar 23 2025 13:10:00 GMT-0800',
    categories: ['Work', 'Personal'],
    order: 20,
  },
  {
    id: 21,
    title: '(title) Develop AI-powered rubber duck debugging system',
    description:
      '(desc) Develop AI-powered rubber duck debugging system that actually swims and quacks debugging advice',
    status: TodoStatus.suggestion,
    date: 'Mon Apr 07 2025 09:30:00 GMT-0800',
    categories: ['Work', 'Personal'],
    order: 21,
  },
  {
    id: 22,
    title: '(title) Start support group for browser tab closers',
    description:
      '(desc) Start support group for people who accidentally closed all their browser tabs without saving',
    status: TodoStatus.suggestion,
    date: 'Wed Apr 09 2025 14:20:00 GMT-0800',
    categories: ['Work', 'Social'],
    order: 22,
  },
  {
    id: 23,
    title: '(title) Create interpretive dance routine for merge conflicts',
    description:
      '(desc) Create interpretive dance routine to explain merge conflicts to junior developers',
    status: TodoStatus.suggestion,
    date: 'Fri Apr 11 2025 11:45:00 GMT-0800',
    categories: ['Work', 'Education'],
    order: 23,
  },
  {
    id: 24,
    title: '(title) Research plant growth with programming languages',
    description:
      '(desc) Research if plants grow better when complimented daily in JavaScript or Python',
    status: TodoStatus.suggestion,
    date: 'Mon Apr 14 2025 16:15:00 GMT-0800',
    categories: ['Personal', 'Hobbies'],
    order: 24,
  },
  {
    id: 25,
    title: '(title) Organize meditation session for telepathic debugging',
    description:
      '(desc) Organize meditation session where participants debug code telepathically',
    status: TodoStatus.suggestion,
    date: 'Thu Apr 17 2025 10:00:00 GMT-0800',
    categories: ['Health', 'Work'],
    order: 25,
  },
];
