export function taggedLog(
  envVarOrVars: string | string[],
  ...messages: unknown[]
): void {
  const vars = Array.isArray(envVarOrVars) ? envVarOrVars : [envVarOrVars];
  // If at least one environment variable is truthy, log:
  if (vars.some((v) => import.meta.env[v])) {
    // eslint-disable-next-line no-console
    console.log(...messages);
  }
}
