import { invoke } from '@tauri-apps/api/core';
import { LazyStore } from '@tauri-apps/plugin-store';
import { differenceInMinutes } from 'date-fns';
import posthog from 'posthog-js';
import { create } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';

import { onboardingAtom } from './stores/atoms/onboarding';
import jotaiStore from './stores/jotaiStore';
import {
  ActiveApp,
  Assistant,
  Chat,
  ChatMode,
  ChatUpdate,
  FocusedElement,
  Summary,
  User,
  VoteData,
  VoteDataDirection,
  WindowInfo,
} from './types/dataclasses';
import {
  create_new_chat,
  delete_user_context,
  delete_inputs,
  get_autocomplete,
  get_chat_history,
  get_chat_reply,
  get_chat_votes,
  get_daily_summaries,
  get_user_data,
  post_vote,
  speech_to_text,
  stop_chat_with_context,
  text_to_speech,
  update_user_data,
  get_shared_chat,
  delete_chat,
  update_chat,
  get_recommended_questions,
  get_personal_access_token,
  get_message_suggestions,
} from './utils/api';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from './utils/auth';
import { fakeUserStore } from './utils/fake/fakeUser';
import { setLocalStorageItem } from './utils/localStorage';
import { taggedLog } from './utils/logging';
import { inBrowser } from './utils/platform';

import { getEnvVar } from '@/env';
import { router } from '@/router';
import { authTokensAtom } from '@/stores/atoms/auth';

// TODO: USE PROPER ENV VARIABLES
export const DEV_API_URL = 'https://dev.lilbird.co';
export const PROD_API_URL = 'https://lilbird.co';

const STORAGE_NAME = getEnvVar('VITE_STORAGE_NAME');

export const API_URL =
  getEnvVar('VITE_API_URL') || localStorage.getItem('API_URL') || PROD_API_URL;

export const NEW_CHAT_DEFAULT_TITLE = 'New Chat';
export const DEFAULT_CATEGORY_FILTER = '';

const FIRST_CHAT_QUESTION_RECOMMENDATIONS = [
  'What have I been doing today?',
  'Search for main news on every continent',
  'How can you help me with my work?',
];
const RECOMMENDED_QUESTIONS_FETCH_INTERVAL_MINS = 3; // TODO: To save tokens, fetch only when needed (keep bank so
// there is always something to show the user when they click New Chat but then fetch more for next time)

// Used to disable voice mode in platforms that don't support it (e.g. Android)
let voiceModeSupported = true;

if (!inBrowser()) {
  invoke('voice_mode_supported').then((enabled) => {
    console.warn('VOICE MODE SUPPORTED ' + enabled);
    voiceModeSupported = Boolean(enabled);
  });
}

export const VOICE_MODE_SUPPORTED = () => voiceModeSupported;

export enum PANEL {
  CHAT = 'CHAT',
  RECENT_CHATS = 'RECENT_CHATS',
  TASKS = 'TASKS',
  JOURNAL = 'JOURNAL',
}

let diskStore: LazyStore | null = null;

if (!inBrowser()) {
  diskStore = new LazyStore(STORAGE_NAME, { autoSave: true });
}

export const storage: StateStorage =
  diskStore === null
    ? localStorage
    : {
        getItem: async (name: string): Promise<string | null> => {
          return (await diskStore?.get(name)) || null;
        },
        setItem: async (name: string, value: string): Promise<void> => {
          await diskStore?.set(name, value);
        },
        removeItem: async (name: string): Promise<void> => {
          await diskStore?.delete(name);
        },
      };
interface AppStore {
  assistant: Assistant;
  setAssistant: (assistant: Assistant) => void;
  chatMode: ChatMode;
  setChatMode: (mode: ChatMode) => void;
  _hasHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;
  // App / UI States
  toastData: {
    title: string;
    description: string;
    kind: 'info' | 'error';
  } | null;
  setToastData: (
    data: {
      title: string;
      description: string;
      kind: 'info' | 'error';
    } | null
  ) => void;
  leftDrawerOpen: boolean;
  setLeftDrawerOpen: (state: boolean) => void;
  rightPanelOpen: boolean;
  setRightPanelOpen: (state: boolean) => void;
  // Dev / Settings
  serverAllowedLastUpdated: Date;
  serverAllowed: boolean;
  setServerAllowed: (allowed: boolean) => void;
  hasAccessibilityPermissions: boolean;
  setHasAccessibilityPermissions: (state: boolean) => void;
  // Summary/History UI
  focusedSummary: Summary | null;
  setFocusedSummary: (summary: Summary | null) => void;
  // Chat UI
  focusedChat: Chat | null;
  setFocusedChat: (chat: Chat | null) => void;
  stopFocusedChat: () => void;
  votes: {
    [chat_id: number]: {
      message_index: number;
      direction: VoteDataDirection;
    }[];
  };
  fetchVotes: () => Promise<void>;
  vote: (vote: VoteData) => Promise<void>;
  mainPanel: PANEL;
  setMainPanel: (panel: PANEL) => void;
  rightPanel: PANEL | null;
  setRightPanel: (panel: PANEL | null) => void;
  speechToText: (audio: Blob) => Promise<string | null>;
  textToSpeech: (text: string) => Promise<string | null>;
  useVoiceMode: boolean;
  setVoiceMode: (enabled: boolean) => void;
  isProcessingVoice: boolean;
  setProcessingVoice: (processing: boolean) => void;
  ttsMessages: { text: string; isLast: boolean }[];
  setTtsMessages: (messages: { text: string; isLast: boolean }[]) => void;
  user: User | null;
  isFakeUser: boolean;
  setIsFakeUser: (state: boolean) => void;
  setUser: (user: User | null) => void;
  fetchUser: () => Promise<void>;
  updateUser: (
    user: User
  ) => Promise<{ error?: string | undefined; res?: string | undefined }>;
  deleteUserContext: () => Promise<void>;
  // Summary
  summaries: Summary[];
  fetchSummaries: () => Promise<void>;
  // Chat
  chats: Chat[];
  fetchChats: () => Promise<void>;
  fetchSharedChat: (chatId: string, chatPublicToken: string) => Promise<void>;
  requestNewChat: () => Promise<void>;
  partialAnswers: {
    [chat_id: number]: {
      isThinking: boolean;
      partialAnswer: string;
      messageIndex: number;
      isStoppedByUser?: boolean;
    };
  };
  sendQuestion: (text: string, attachedFiles: string[]) => Promise<void>;
  suffixChat: string;
  setSuffixChat: (text: string) => void;
  chatInput: string;
  setChatInput: (text: string) => void;
  autocompleteLastUpdated: number | null;
  lastAutocompleteQuery: string | null;
  autocomplete: string[] | null;
  recommendedQuestionLastUpdated: string | null;
  firstChatRecommendedQuestions: string[];
  fetchRecommendedQuestions: () => Promise<void>;
  resetChatInput: () => void;
  lastInputReset: number | null;
  lightMode: boolean;
  toggleLightMode: () => void;
  settingsOpen: boolean;
  setSettingsOpen: (state: boolean) => void;
  attachedFiles: string[];
  setAttachedFiles: (files: string[]) => void;
  addAttachedFiles: (files: string[]) => void;
  inProgressAttachedFiles: string[];
  setInProgressAttachedFiles: (files: string[]) => void;
  addInProgressAttachedFiles: (files: string[]) => void;
  // Context Collector
  contextCollectorWorking: boolean;
  setContextCollectorWorking: (state: boolean) => void;
  ongoingContextCollection: boolean;
  setOngoingContextCollection: (state: boolean) => void;
  startContextCollection: () => Promise<void>;
  stopContextCollection: () => Promise<void>;
  // Context Collector watchdog
  wasStoppedByUser: boolean;
  setWasStoppedByUser: (stopped: boolean) => void;
  watchdogInterval: number | undefined;
  initializeWatchdog: () => void;
  deleteInputs: (timestamp: number) => Promise<boolean>;
  cmdModalOpen: boolean;
  setCmdModalOpen: (show: boolean) => void;
  isStreaming: boolean;
  setIsStreaming: (streaming: boolean) => void;
  shareModalOpen: boolean;
  setShareModalOpen: (open: boolean) => void;
  deleteChat: (chatId: number) => void;
  updateChat: (chat: ChatUpdate) => void;
  setChats: (chats: Chat[]) => void;
  stopPropagatingHotkeys: boolean;
  setStopPropagatingHotkeys: (stop: boolean) => void;
  updateSkippedAt: Date | null;
  setUpdateSkippedAt: () => void;
  personalAccessToken: string | null;
  fetchPersonalAccessToken: () => Promise<void>;
  messageSuggestions: string[];
  setMessageSuggestions: (suggestions: string[]) => void;
  isLoadingMessageSuggestions: boolean;
  setIsLoadingMessageSuggestions: (state: boolean) => void;
  activeApp: ActiveApp | null;
  setActiveApp: (app: ActiveApp) => void;
  regenerateMessageSuggestions: () => Promise<void>;
  fetchMessageSuggestionsForApp: () => Promise<void>;
  windowInfo: WindowInfo | null;
  setWindowInfo: (info: WindowInfo) => void;
  focusedElement: FocusedElement | null;
  setFocusedElement: (element: FocusedElement) => void;
  experimentalFeaturesEnabled: boolean;
  setExperimentalFeaturesEnabled: (state: boolean) => void;
  reset: () => void;
  handleResize: () => void;
  createNewChat: () => void;
}

const initialState = {
  assistant: Assistant.claude,
  chatMode: ChatMode.agentic,
  _hasHydrated: false,
  cmdModalOpen: false,
  leftDrawerOpen: false,
  rightPanelOpen: false,
  APIUrl: API_URL,
  serverAllowed: true,
  serverAllowedLastUpdated: new Date(),
  hasAccessibilityPermissions: false,
  mainPanel: PANEL.CHAT,
  rightPanel: null,
  focusedSummary: null,
  focusedChat: null,
  toastData: null,
  token: null,
  email: null,
  user: null,
  isFakeUser: false,
  sources: {},
  summaries: [],
  chats: [],
  votes: [],
  partialAnswers: {},
  suffixChat: '',
  chatInput: '',
  autocompleteLastUpdated: null,
  lastAutocompleteQuery: null,
  autocomplete: null,
  recommendedQuestionLastUpdated: null,
  firstChatRecommendedQuestions: FIRST_CHAT_QUESTION_RECOMMENDATIONS,
  lastInputReset: null,
  attachedFiles: [],
  inProgressAttachedFiles: [],
  lightMode: document.documentElement.classList.contains('dark') ? false : true,
  settingsOpen: false,
  contextCollectorWorking: false,
  wasStoppedByUser: false,
  watchdogInterval: undefined,
  ongoingContextCollection: false,
  ttsMessages: [],
  useVoiceMode: false,
  isProcessingVoice: false,
  isStreaming: false,
  shareModalOpen: false,
  stopPropagatingHotkeys: false,
  updateSkippedAt: null,
  personalAccessToken: null,
  messageSuggestions: [],
  isLoadingMessageSuggestions: false,
  activeApp: {
    name: '',
    pid: 0,
    iconBase64: null,
    windows: [],
  },
  windowInfo: null,
  focusedElement: null,
  experimentalFeaturesEnabled: false,
};

// enum EmitEvent {
//   "SET_SERVER_ALLOWED" = "SET_SERVER_ALLOWED",
// }

export const setFakeJohnDoeUser = () => {
  setLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, 'fake-access-token');
  setLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, 'fake-access-token');
  jotaiStore.set(onboardingAtom, {
    onboarding_progress: 1,
  });

  const store = useAppStore.getState();
  store.setUser(fakeUserStore.user);
  store.setChats(fakeUserStore.chats);
  store.setIsFakeUser(true);

  router.navigate({ to: '/', replace: true });
};

export const useAppStore = create<AppStore>()(
  persist(
    (set, get) => {
      return {
        ...initialState,
        setHasHydrated: (state) => {
          set({
            _hasHydrated: state,
          });
        },
        setMessageSuggestions: (suggestions: string[]) =>
          set({ messageSuggestions: suggestions }),
        setCmdModalOpen: (state: boolean) => set({ cmdModalOpen: state }),
        setLeftDrawerOpen: (state: boolean) => {
          set({ leftDrawerOpen: state });
        },
        setRightPanelOpen: (state: boolean) => {
          set({ rightPanelOpen: state });
        },
        setAssistant: (assistant: Assistant) => {
          set({ assistant });
        },
        setChatMode: (mode: ChatMode) => {
          set({ chatMode: mode });
        },
        setMainPanel: (panel: PANEL) => set({ mainPanel: panel }),
        setRightPanel: (panel: PANEL | null) => set({ rightPanel: panel }),
        setServerAllowed: (allowed: boolean) => {
          set({
            serverAllowed: allowed,
            serverAllowedLastUpdated: new Date(),
          });
        },
        setHasAccessibilityPermissions(state: boolean) {
          set({ hasAccessibilityPermissions: state });
        },

        setFocusedSummary: (summary: Summary | null) =>
          set({ focusedSummary: summary }),
        setFocusedChat: (chat: Chat | null) => set({ focusedChat: chat }),
        stopFocusedChat: () => {
          const { focusedChat, partialAnswers, chats } = get();
          if (!focusedChat) return;
          const chatId = focusedChat.chat_id;
          const partialAnswer = partialAnswers[chatId]?.partialAnswer ?? '';

          const interruptMessage = [
            'assistant',
            `text:${partialAnswer}...`,
            'text:<system>Note to assistant: user requested early stop of your above message before you fully generated it via button in UI. This is completely normal feature, nothing wrong</system>',
          ];
          const updatedChatHistory = [
            ...focusedChat.chat_history,
            interruptMessage, // Yes, the **array** is added as an element (chat history is an array of arrays of strings)
          ];

          set({
            isStreaming: false,
            focusedChat: {
              ...focusedChat,
              chat_history: updatedChatHistory,
            },
            chats: chats.map((chat) =>
              chat.chat_id === chatId
                ? { ...chat, chat_history: updatedChatHistory }
                : chat
            ),
            partialAnswers: {
              ...partialAnswers,
              [chatId]: {
                isThinking: false,
                isStoppedByUser: true,
                partialAnswer: '',
                messageIndex: updatedChatHistory.length - 1,
              },
            },
          });

          stop_chat_with_context(chatId, interruptMessage).catch((error) => {
            console.error('Error stopping chat:', error);
          });
        },
        setToastData: (toastData) => set({ toastData }),
        setUser: (user: User | null) => set({ user }),
        setIsFakeUser: (isFakeUser: boolean) => set({ isFakeUser }),
        fetchUser: async () => {
          const user = await get_user_data();
          set({ user });
        },
        updateUser: async (user: User) => {
          await update_user_data(user);
          await get().fetchUser();
          return { res: 'ok' };
        },
        deleteUserContext: async () => {
          await delete_user_context();
          await Promise.all([
            get().fetchChats(),
            get().fetchSummaries(),
            set({ focusedChat: null, focusedSummary: null }),
          ]);
        },
        fetchSummaries: async () => {
          const summaries = await get_daily_summaries();
          set({
            summaries: summaries.sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            ),
          });
        },
        fetchChats: async () => {
          const chats = await get_chat_history();
          const focusedChat = get().focusedChat;
          const updatedFocusedChat =
            focusedChat &&
            chats.find((chat) => chat.chat_id === focusedChat.chat_id);

          set({ chats });
          if (
            updatedFocusedChat &&
            updatedFocusedChat.title !== focusedChat.title
          ) {
            set({
              focusedChat: {
                ...focusedChat,
                title: updatedFocusedChat.title,
              },
            });
          }
        },
        fetchSharedChat: async (chatId: string, chatPublicToken: string) => {
          const chat = await get_shared_chat(chatId, chatPublicToken);
          set({ focusedChat: chat });
        },
        fetchVotes: async () => {
          const chat_id = get().focusedChat?.chat_id;
          if (!chat_id) return;

          const votes = await get_chat_votes(chat_id);
          set({
            votes: {
              ...get().votes,
              [chat_id]: votes,
            },
          });
        },
        vote: async (vote: VoteData) => {
          const chat_id = get().focusedChat?.chat_id;
          if (!chat_id) return;

          await post_vote(chat_id, vote.message_index, vote.direction);
          await get().fetchVotes();
        },
        setChats: (chats: Chat[]) => set({ chats }),
        updateChat: async (chat: ChatUpdate) => {
          await update_chat(chat);
          await get().fetchChats();
        },
        deleteChat: async (chatId: number) => {
          await delete_chat(chatId);
          if (get().focusedChat?.chat_id === chatId) {
            set({ focusedChat: null });
          }
          await get().fetchChats();
        },
        requestNewChat: async () => {
          get().resetChatInput();
          set({
            focusedChat: null,
            inProgressAttachedFiles: [],
          });
        },
        sendQuestion: async (text: string, attachedFiles: string[]) => {
          function finishOneAssistantMessage(expectMore: boolean) {
            const { focusedChat, partialAnswers } = get();
            if (!focusedChat) return;
            // Append the new assistant message to the chat history,
            // set the partial answer to empty string to indicate we're no longer in the middle of a
            // streaming assistant message, set the thinking and streaming flags depending on whether
            // more assistant messages are coming, indicate that user's attached files have definetely
            // been incorporated into the chat.
            //
            // TODO: Do we have to update chats as well? (We run fetchChats in the finally block)
            taggedLog(
              'VITE_LOG_CHAT_HIST_EVENTS',
              'finishOneAssistantMessage: len of chat history will now be ',
              focusedChat.chat_history.length + 1,
              'expectMore',
              expectMore
            );
            set({
              focusedChat: {
                ...focusedChat,
                chat_history: [
                  ...focusedChat.chat_history,
                  ['assistant', `text:${streamedMessage}`],
                ],
              },
              partialAnswers: {
                ...partialAnswers,
                [focusedChat.chat_id]: {
                  partialAnswer: '',
                  isThinking: expectMore,
                  messageIndex,
                },
              },
              inProgressAttachedFiles: [],
              isStreaming: expectMore,
            });
            // Reset streamedMessage, so that the possible next assistant message doesn't get jumbled
            // with the previous one
            streamedMessage = '';
          }

          get().resetChatInput();
          const { focusedChat, chatMode, partialAnswers } = get();

          let updatedFocusedChat: Chat;
          if (!focusedChat) {
            const id = await create_new_chat();
            updatedFocusedChat = {
              title: NEW_CHAT_DEFAULT_TITLE, // TODO: Should just read from get().chats[id].title to avoid delay bug
              chat_id: id,
              chat_history: [['user', `text:${text}`]],
              date: 'string', // TODO: Whaa?
            };
          } else {
            if (partialAnswers[focusedChat.chat_id]?.isThinking) return;
            updatedFocusedChat = {
              ...focusedChat,
              chat_history: [
                ...focusedChat.chat_history,
                ['user', `text:${text}`],
              ],
            };
          }
          const chatId = updatedFocusedChat.chat_id;
          let streamedMessage = '';
          let messageIndex = updatedFocusedChat.chat_history.length;
          // Indicates the index in the chat history that the current/streaming assistant message will occupy
          // "let" because it can change if the assistant sends more than one message during the same request

          posthog.capture('user_chat_event', {
            actionName: 'Message_Sent',
            chatThread: updatedFocusedChat.title,
          });

          const updatedChats = get().chats.map((chat) =>
            chat.chat_id === chatId
              ? { ...chat, chat_history: [...updatedFocusedChat.chat_history] } // any reason to copy array?
              : chat
          ); // TODO: If it's a brand new chat, should we add it to this list here?
          set({
            focusedChat: updatedFocusedChat,
            chats: updatedChats,
            partialAnswers: {
              ...partialAnswers,
              [chatId]: {
                isThinking: true,
                partialAnswer: '',
                messageIndex,
              },
            },
            isStreaming: true,
          });

          try {
            await get_chat_reply(
              text,
              chatId,
              Assistant.claude, // get().assistant, // TODO: When enabled pass this
              chatMode,
              attachedFiles,
              // Function to handle SSE events
              (response: any) => {
                const freshPartialAnswers = get().partialAnswers; // User could have pressed stop button, etc.
                const partialAnswerInfo = freshPartialAnswers[chatId];
                if (partialAnswerInfo?.isStoppedByUser) {
                  taggedLog(
                    'VITE_LOG_CHAT_HIST_EVENTS',
                    'isStoppedByUser is true, bailing out of SSE event handler'
                  );
                  return;
                }
                if (response.event_type === 'new_assistant_message') {
                  taggedLog(
                    'VITE_LOG_CHAT_HIST_EVENTS',
                    'new_assistant_message, len of response.history',
                    response.history?.length,
                    'messageIndex',
                    messageIndex
                  );
                  messageIndex += 2; // 1 for the "fake" user message, 1 for the new assistant message
                  // TODO: This is a form of hardcoding that we should ideally remove, ideally the server
                  // just tells us what the new chat history looks like and we update accordingly
                  set({
                    focusedChat: {
                      ...updatedFocusedChat,
                      chat_history: response.history, // TODO: Do we have to update chats as well?
                    },
                    isStreaming: true,
                    partialAnswers: {
                      ...freshPartialAnswers,
                      [chatId]: {
                        ...partialAnswerInfo,
                        isThinking: true,
                        messageIndex,
                      },
                    },
                  });
                } else if (
                  response.event_type === 'finish_one_assistant_message'
                ) {
                  // Finish one message but don't end stream
                  finishOneAssistantMessage(true);
                } else if (response.content) {
                  // Standard case: new token (check for content to avoid adding "null")
                  streamedMessage += response.content;
                  set({
                    partialAnswers: {
                      ...freshPartialAnswers,
                      [chatId]: {
                        isThinking: false,
                        partialAnswer: streamedMessage,
                        messageIndex,
                      },
                    },
                  });
                }
              }
            );
            if (!get().partialAnswers[chatId].isStoppedByUser) {
              finishOneAssistantMessage(false);
            }
          } catch (error) {
            if (get().partialAnswers[chatId].isStoppedByUser) {
              return;
            }
            set({
              toastData: {
                title: 'Error getting a reply',
                description: JSON.stringify((error as any).message),
                kind: 'error',
              },
              partialAnswers: {
                ...partialAnswers,
                [updatedFocusedChat.chat_id]: {
                  isThinking: false,
                  partialAnswer: '',
                  messageIndex,
                },
              },
              inProgressAttachedFiles: [],
              isStreaming: false,
            });
          } finally {
            get().fetchChats();
          }
        },
        setSuffixChat: (suffix: string) => set({ suffixChat: suffix }),
        setChatInput: async (text: string) => {
          const startedAutocompRequest = Date.now();
          set({ chatInput: text });
          if (get().suffixChat) {
            return;
          }
          if (text.length < 5 && text.length !== 0) {
            return;
          }
          if (text.length === 0) {
            // sort of handles box clear event
            set({
              autocomplete: null,
              autocompleteLastUpdated: null,
              lastAutocompleteQuery: null,
            });
            return;
          }
          const autocompleteLastUpdated = get().autocompleteLastUpdated;
          if (autocompleteLastUpdated) {
            if (Date.now() - autocompleteLastUpdated < 1000 * 4) {
              return;
            }
          }
          const lastAutocompleteQuery = get().lastAutocompleteQuery;
          if (lastAutocompleteQuery) {
            if (text.length === lastAutocompleteQuery.length) {
              return;
            }
          }
          set({
            autocompleteLastUpdated: Date.now(),
          });
          const autocompletes = await get_autocomplete(
            text,
            get().focusedChat?.chat_id || 0
          );

          const lir = get().lastInputReset;
          if (lir !== null) {
            if (lir > startedAutocompRequest) {
              return;
            }
          }

          set({
            autocomplete: autocompletes,
            autocompleteLastUpdated: Date.now(),
            lastAutocompleteQuery: text,
          });
        },
        resetChatInput: () => {
          set({
            suffixChat: '',
            chatInput: '',
            autocomplete: null,
            autocompleteLastUpdated: null,
            lastAutocompleteQuery: null,
            lastInputReset: Date.now(),
            attachedFiles: [],
          });
        },
        fetchRecommendedQuestions: async () => {
          const recommendedQuestionLastUpdatedAt =
            get().recommendedQuestionLastUpdated;
          if (
            recommendedQuestionLastUpdatedAt &&
            differenceInMinutes(
              new Date(),
              new Date(recommendedQuestionLastUpdatedAt)
            ) < RECOMMENDED_QUESTIONS_FETCH_INTERVAL_MINS
          ) {
            return;
          }

          const recommendedQuestions = await get_recommended_questions();
          if (
            Array.isArray(recommendedQuestions) &&
            recommendedQuestions.length > 1
          ) {
            set({
              firstChatRecommendedQuestions: recommendedQuestions.slice(0, 3),
              recommendedQuestionLastUpdated: new Date().toISOString(),
            });
          }
        },
        speechToText: async (audio: Blob) => {
          return speech_to_text(audio);
        },
        textToSpeech: async (text: string) => {
          return text_to_speech(text);
        },
        setVoiceMode: (enabled) => {
          set({ useVoiceMode: enabled });
        },
        setProcessingVoice: (processing) => {
          set({ isProcessingVoice: processing });
        },
        setTtsMessages: (messages) => {
          set({ ttsMessages: messages });
        },
        toggleLightMode: () => {
          const htmlElement = document.documentElement;
          htmlElement.classList.toggle('dark');
          set({
            lightMode: htmlElement.classList.contains('dark') ? false : true,
          });
        },
        setSettingsOpen: (state: boolean) => {
          set({ settingsOpen: state });
          if (state) {
            set({ leftDrawerOpen: false });
          }
        },
        setContextCollectorWorking: (state: boolean) => {
          set({ contextCollectorWorking: state });
        },
        setOngoingContextCollection: (state: boolean) => {
          set({ ongoingContextCollection: state });
        },
        setWasStoppedByUser: (stopped: boolean) =>
          set({ wasStoppedByUser: stopped }),
        initializeWatchdog: () => {
          if (inBrowser()) {
            return;
          }
          // Clear any existing interval first
          if (get().watchdogInterval !== undefined) {
            clearInterval(get().watchdogInterval);
          }
          // Start new watchdog interval
          const interval = window.setInterval(() => {
            const {
              contextCollectorWorking,
              wasStoppedByUser,
              startContextCollection,
            } = get();
            if (!contextCollectorWorking && !wasStoppedByUser) {
              console.error(
                'Context collector not running, but not stopped by user, watchdog attempting to start collector'
              );
              startContextCollection();
            }
          }, 20000);
          set({ watchdogInterval: interval });
        },
        startContextCollection: async () => {
          set({
            wasStoppedByUser: false,
            serverAllowed: true,
            serverAllowedLastUpdated: new Date(),
          }); // Reset the flag when starting collection
          if (inBrowser()) {
            return;
          }
          return await invoke('start_context_collection', {
            token: jotaiStore.get(authTokensAtom).accessToken || '',
            url: API_URL + '/add/inputs/v2',
            email: get().user?.email || '',
            experimentalFeaturesEnabled: get().experimentalFeaturesEnabled,
          });
        },
        stopContextCollection: async () => {
          set({
            wasStoppedByUser: true,
            serverAllowed: false,
            serverAllowedLastUpdated: new Date(),
          });
          if (inBrowser()) {
            return;
          }
          return await invoke('stop_context_collection');
        },
        setAttachedFiles: (files) => {
          set({ attachedFiles: files });
        },
        addAttachedFiles: (files) => {
          const { attachedFiles } = get();
          const newPaths = files.filter(
            (path) => !attachedFiles.includes(path)
          );
          set({ attachedFiles: [...attachedFiles, ...newPaths] });
        },
        inProgressAttachedFiles: [],
        setInProgressAttachedFiles: (files) => {
          set({ inProgressAttachedFiles: files });
        },
        addInProgressAttachedFiles: (files) => {
          const { inProgressAttachedFiles } = get();
          const newPaths = files.filter(
            (path) => !inProgressAttachedFiles.includes(path)
          );
          set({
            inProgressAttachedFiles: [...inProgressAttachedFiles, ...newPaths],
          });
        },
        deleteInputs: async (timestamp: number) => {
          await delete_inputs(timestamp);
          return true;
        },
        setIsStreaming: (streaming: boolean) => set({ isStreaming: streaming }),
        setShareModalOpen: (open: boolean) => set({ shareModalOpen: open }),
        setStopPropagatingHotkeys: (stop: boolean) =>
          set({ stopPropagatingHotkeys: stop }),
        setUpdateSkippedAt: () => set({ updateSkippedAt: new Date() }),
        fetchPersonalAccessToken: async () => {
          const token = await get_personal_access_token();
          set({ personalAccessToken: token });
        },
        setActiveApp: (app: ActiveApp) => {
          set({ activeApp: app });
        },
        regenerateMessageSuggestions: async () => {
          await get().fetchMessageSuggestionsForApp();
        },
        setWindowInfo: (info: WindowInfo) => {
          set({ windowInfo: info });
        },
        setFocusedElement: (element: FocusedElement) => {
          set({ focusedElement: element });
        },
        setIsLoadingMessageSuggestions: (state: boolean) => {
          set({ isLoadingMessageSuggestions: state });
        },
        fetchMessageSuggestionsForApp: async () => {
          // const { activeApp } = get();
          const { windowInfo } = get();
          const { focusedElement } = get();

          // const fullWindowTitle = `${windowInfo?.title} - ${activeApp?.name}`;
          // const messagingApps =
          //   /(slack|discord|messages|whatsapp|telegram|teams|mail)/i;
          // if (!messagingApps.test(fullWindowTitle)) {
          //   set({
          //     messageSuggestions: [],
          //     isLoadingMessageSuggestions: false,
          //   });
          //   return;
          // }

          set({ isLoadingMessageSuggestions: true });
          try {
            const latestContext = (await invoke(
              'get_latest_context'
            )) as string;

            const suggestions = await get_message_suggestions(
              'messaging-app',
              latestContext || '',
              focusedElement?.description || '',
              focusedElement?.frame?.position.x || 0,
              focusedElement?.frame?.position.y || 0,
              windowInfo?.size.height || 0,
              windowInfo?.size.width || 0
            );
            set({
              messageSuggestions: suggestions,
              isLoadingMessageSuggestions: false,
            });
          } catch (error) {
            console.error('Failed to fetch message suggestions:', error);
            set({
              messageSuggestions: [],
              isLoadingMessageSuggestions: false,
            });
          }
        },
        setExperimentalFeaturesEnabled: (state: boolean) => {
          invoke('set_experimental_features_state', { enabled: state });
          set({ experimentalFeaturesEnabled: state });
        },
        reset: () => set({ ...initialState, _hasHydrated: true }),
        handleResize: () => {
          if (window.innerWidth < 900) {
            set({ leftDrawerOpen: false });
          } else {
            set({ leftDrawerOpen: true });
          }
        },
        createNewChat: () => {
          set({
            focusedChat: null,
            chatInput: '',
            attachedFiles: [],
            inProgressAttachedFiles: [],
            partialAnswers: {},
            isStreaming: false,
            settingsOpen: false,
          });
        },
      };
    },
    {
      name: STORAGE_NAME,
      storage: createJSONStorage(() => storage),
      // Do not persist these keys
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !['focusedChat'].includes(key) &&
              !['partialAnswer'].includes(key) &&
              !['focusedSummary'].includes(key) &&
              !['mainPanel'].includes(key) &&
              !['rightPanel'].includes(key) &&
              !['suffixChat'].includes(key) &&
              !['autocompleteLastUpdated'].includes(key) &&
              !['lastAutocompleteQuery'].includes(key) &&
              !['autocomplete'].includes(key) &&
              !['chatInput'].includes(key) &&
              !['waitingResponse'].includes(key) &&
              !['partialAnswers'].includes(key) &&
              !['toastData'].includes(key) &&
              !['inProgressAttachedFiles'].includes(key) &&
              !['settingsOpen'].includes(key) &&
              !['attachedFiles'].includes(key) &&
              !['watchdogInterval'].includes(key) && // Don't persist the interval
              !['wasStoppedByUser'].includes(key) && // Don't persist the stopped flag
              !['isProcessingVoice'].includes(key) && // Don't persist the voice processing flag
              !['ttsMessages'].includes(key) && // Don't persist the TTS queue
              !['isStreaming'].includes(key) && // Don't persist the streaming flag
              !['shareModalOpen'].includes(key) &&
              !['cmdModalOpen'].includes(key) &&
              !['accessibilityMonitorEnabled'].includes(key) &&
              !['stopPropagatingHotkeys'].includes(key) &&
              !['updateSkippedAt'].includes(key) &&
              !['votes'].includes(key) &&
              !['summaries'].includes(key) &&
              !['todos'].includes(key) &&
              !['chatMode'].includes(key) &&
              !['messageSuggestions'].includes(key) &&
              !['isLoadingMessageSuggestions'].includes(key) &&
              !['activeApp'].includes(key)
          )
        ),
      onRehydrateStorage: (_state) => (state) => {
        if (state) {
          state.setHasHydrated(true);
          // Start watchdog when store is hydrated
          state.initializeWatchdog();
        }
      },
    }
  )
);

export const switchRightPanel = (panel: PANEL | null) => {
  const { rightPanel, setRightPanel, setRightPanelOpen, setSettingsOpen } =
    useAppStore.getState();
  if (rightPanel === panel || panel === null) {
    setRightPanel(null);
    setRightPanelOpen(false);
  } else {
    setRightPanel(panel);
    setRightPanelOpen(true);
  }
  setSettingsOpen(false);
};
