import { captureException } from '@sentry/react';
import { invoke } from '@tauri-apps/api/core';
import { platform } from '@tauri-apps/plugin-os';
import { check, Update } from '@tauri-apps/plugin-updater';
import { millisecondsInHour } from 'date-fns/constants';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getEnvVar } from '@/env';
import { useAppStore } from '@/store';
import { inBrowser } from '@/utils/platform';

const isDev = getEnvVar('VITE_ENV') === 'development';
interface CheckForUpdateOptions {
  silent?: boolean;
}

export interface UpdateContextType {
  updateAvailable: Update | null;
  runUpdate: () => void;
  downloadProgress: number | null;
  checkForUpdate: (options?: CheckForUpdateOptions) => Promise<void>;
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUpdate = (): UpdateContextType => {
  const context = useContext(UpdateContext);
  if (context === undefined) {
    throw new Error('useUpdate must be used within an UpdateProvider');
  }
  return context;
};

// eslint-disable-next-line react-refresh/only-export-components
export const UpdateContext = createContext<UpdateContextType | undefined>(
  undefined
);

export const UpdateProvider = ({ children }: { children: ReactNode }) => {
  const { setToastData, isFakeUser } = useAppStore((state) => state);
  const [updateAvailable, setUpdateAvailable] = useState<Update | null>(null);
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);

  const checkForUpdate = useCallback(
    async (options?: CheckForUpdateOptions) => {
      if (isFakeUser) return;
      const update = await check();
      if (update?.available) {
        setUpdateAvailable(update);
        setToastData({
          title: 'Update Available',
          description: `Version ${update.version} is available.`,
          kind: 'info',
        });
      } else if (!options?.silent) {
        setToastData({
          title: 'No Updates Available',
          description: 'You are running the latest version.',
          kind: 'info',
        });
      }
    },
    [setToastData, isFakeUser]
  );

  useEffect(() => {
    const os = !inBrowser() && platform();
    const onMobile = os === 'android' || os === 'ios';

    if (!inBrowser() && !onMobile && isDev) {
      checkForUpdate({ silent: true });
      const pollInterval = setInterval(
        () => checkForUpdate({ silent: true }),
        millisecondsInHour / 6 // every 10 minutes
      );
      return () => {
        clearInterval(pollInterval);
      };
    }
  }, [checkForUpdate]);

  const runUpdate = useCallback(async () => {
    let downloaded = 0;
    let contentLength: number | undefined = 0;
    try {
      if (!updateAvailable) return;

      console.info('Downloading and installing update');
      await updateAvailable.downloadAndInstall((event) => {
        switch (event.event) {
          case 'Started':
            contentLength = event.data.contentLength;
            break;
          case 'Progress':
            downloaded += event.data.chunkLength;
            if (contentLength) {
              setDownloadProgress((downloaded / contentLength) * 100);
            }
            break;
          case 'Finished':
            console.info('Update downloaded and installed');
            setDownloadProgress(null);
            break;
        }
      });

      console.info('Relaunching app');
      invoke('relaunch_app');
    } catch (err: any) {
      console.info(
        `Failed to download and install update : ${JSON.stringify(err)}`
      );
      // Send to Sentry with additional context
      captureException(err, {
        extra: {
          downloaded,
          contentLength,
          updateVersion: updateAvailable?.version,
          currentVersion: updateAvailable?.currentVersion,
          errorDetails: err?.message,
          fullError: JSON.stringify(err),
        },
        tags: {
          type: 'update_failure',
          component: 'AutoUpdater',
        },
      });

      setToastData({
        title: 'Failed to download and install update',
        description: err?.message,
        kind: 'error',
      });
    }
  }, [setToastData, updateAvailable]);

  return (
    <UpdateContext.Provider
      value={{
        updateAvailable,
        runUpdate,
        downloadProgress,
        checkForUpdate,
      }}
    >
      {children}
    </UpdateContext.Provider>
  );
};
