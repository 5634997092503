import Logo from '../assets/icons/logo.svg';

import Button from '@/components/ui/Button';
import CenteredTile from '@/components/ui/CenteredTile';
import { useUpdate } from '@/providers/UpdateProvider';
import { handleMailTo } from '@/utils/platform';

function UpdateScreen() {
  const { downloadProgress, runUpdate, updateAvailable } = useUpdate();

  return (
    <CenteredTile
      title="Update to the latest version"
      leadingContent={<img src={Logo} alt="Little Bird" className="mx-auto" />}
      description={
        updateAvailable?.version
          ? 'V ' + updateAvailable?.version + ' is now available for download'
          : 'A new version is available for download'
      }
      content={
        <>
          <Button
            className="w-full mb-6"
            type="button"
            disabled={downloadProgress !== null}
            onPress={() => {
              runUpdate();
            }}
          >
            {downloadProgress !== null ? 'Downloading...' : 'Update'}
          </Button>
          {downloadProgress !== null && (
            <div className="my-4">
              <div
                className="flex w-full h-2 bg-darkLine rounded-full overflow-hidden dark:bg-neutral-700"
                role="progressbar"
                aria-valuenow={downloadProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="flex flex-col justify-center rounded-full overflow-hidden bg-cta-gradient text-xs text-white text-center whitespace-nowrap transition duration-500 "
                  style={{ width: `${downloadProgress}%` }}
                />
              </div>
            </div>
          )}
          <p className="mt-2 text-center text-darkSecondary text-sm">
            Trouble logging in?{' '}
            <span
              className="text-darkPrimary cursor-pointer underline"
              onClick={() => {
                handleMailTo(
                  'support@genos.dev',
                  'I need assistance',
                  'I cant login'
                );
              }}
            >
              Contact Support
            </span>
          </p>
        </>
      }
    />
  );
}

export default UpdateScreen;
