import { fakeCategories } from './fakeCategories';
import { fakeChats } from './fakeChats';
import { fakeTodos } from './fakeTodos';
// sources: {},
// summaries: [],
// chats: [],
// votes: [],
// partialAnswers: {},
// ttsMessages: [],
export const fakeUserStore = {
  _hasHydrated: true,
  hasAccessibilityPermissions: true,
  ongoingContextCollection: false,
  email: 'john.doe@gmail.com',
  user: {
    email: 'john.doe@gmail.com',
    global_prompt: 'global_prompt',
    latest_input: 'latest_input',
    total_inputs: 'total_inputs',
    todo_prompt: 'todo_prompt',
    summary_prompt: 'summary_prompt',
    info_to_ignore_prompt: 'info_to_ignore_prompt',
    name: 'John Doe',
    aliases: 'John Doodoo',
  },
  todos: fakeTodos,
  categories: fakeCategories,
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  chats: fakeChats,
};
