import { RouterProvider as TanstackRouterProvider } from '@tanstack/react-router';
import { getCurrentWindow } from '@tauri-apps/api/window';
import { useEffect } from 'react';

import { router } from '@/router';
import { inBrowser } from '@/utils/platform';

export function RouterProvider() {
  // Hack for the Webkit backdrop bug.
  // See: https://discussions.apple.com/thread/255764118?sortBy=rank
  useEffect(() => {
    if (inBrowser() || getCurrentWindow().label !== 'main') return;

    const rootElement = document.getElementById('root');

    if (rootElement) {
      rootElement.style.backgroundColor = 'rgb(17, 17, 17,0.8)';
    }
  }, []);

  return <TanstackRouterProvider router={router} />;
}
