import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TileProps {
  icon?: IconDefinition;
  title: string;
  description: string;
  content: JSX.Element;
  leadingContent?: JSX.Element;
  titleClassName?: string;
  descriptionClassName?: string;
}

export default function Tile({
  icon,
  title,
  description,
  content,
  leadingContent,
  titleClassName = '',
  descriptionClassName = '',
}: TileProps) {
  return (
    <div className="h-full max-h-[80vh] overflow-auto w-full max-w-[560px] px-6 py-8 flex flex-col gap-6">
      {icon && (
        <FontAwesomeIcon
          size="2x"
          icon={icon}
          className="self-center text-darkPrimary"
        />
      )}
      {leadingContent && leadingContent}
      <div className="flex flex-col gap-2 items-center text-center">
        <p className={`text-xl text-darkPrimary ${titleClassName}`}>{title}</p>
        <p
          className={`text-sm text-darkPrimary opacity-60 ${descriptionClassName}`}
        >
          {description}
        </p>
      </div>
      <div>{content}</div>
    </div>
  );
}
