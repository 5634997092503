import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import Tile from './Tile';

interface CenteredTileProps {
  icon?: IconDefinition;
  title: string;
  description: string;
  content: JSX.Element;
  leadingContent?: JSX.Element;
  titleClassName?: string;
  descriptionClassName?: string;
}

export default function CenteredTile({
  icon,
  title,
  description,
  content,
  leadingContent,
  titleClassName = '',
  descriptionClassName = '',
}: CenteredTileProps) {
  return (
    <div className="flex fixed inset-0 w-screen min-h-full items-center justify-center p-4">
      <div className="bg-darkShadow rounded shadow-card border border-darkLine w-[560px]">
        <Tile
          icon={icon}
          title={title}
          description={description}
          leadingContent={leadingContent}
          titleClassName={titleClassName}
          descriptionClassName={descriptionClassName}
          content={content}
        />
      </div>
    </div>
  );
}
