import { Chat } from '@/types/dataclasses';

export const fakeChats: Chat[] = [
  {
    chat_id: 1,
    title: 'Chat 1',
    chat_history: [
      ['user', 'text: Hello, how are you?'],
      ['assistant', 'text: I am fine, thank you!'],
      ['user', 'text: What is your name?'],
      ['assistant', 'text: I am a chatbot.'],
      ['user', 'text: What is your favorite color?'],
      ['assistant', 'text: I am a chatbot.'],
      ['user', 'text: What is your favorite color?'],
      ['assistant', 'text: I am a chatbot.'],
      ['user', 'text: What is your favorite position?'],
      ['assistant', 'text: I am a chatbot.'],
      ['user', 'text: What is your favorite position?'],
      ['assistant', 'text: I am a chatbot.'],
      ['user', 'text: What is your favorite position?'],
      ['assistant', 'text: I am a chatbot.'],
      ['user', 'text: What is your favorite position?'],
      ['assistant', 'text: I am a chatbot.'],
    ],
    date: '2021-01-01',
  },
  {
    chat_id: 2,
    title: 'Chat 2',
    chat_history: [
      ['user', 'text: yo, whatsup?'],
      ['assistant', 'text: not much, you?'],
    ],
    date: '2021-01-02',
  },
];
