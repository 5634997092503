import { fetchEventSource } from '@microsoft/fetch-event-source';

import { API_URL } from '../store';
import {
  Assistant,
  Chat,
  ChatMode,
  ChatUpdate,
  Source,
  Summary,
  Todo,
  TodoCreate,
  TodoUpdate,
  User,
  UserUpdate,
  VoteData,
  VoteDataDirection,
} from '../types/dataclasses';

import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, decodeIdToken } from './auth';
import { getAxiosInstance } from './axios';
import { getFileFromPath } from './files';
import { getLocalStorageItem } from './localStorage';

import { getEnvVar } from '@/env';

export async function exchange_code_for_token(authCode: string, email: string) {
  const response = await fetch(
    `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/oauth/token`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
        client_id: getEnvVar('VITE_AUTH0_CLIENT_ID'),
        username: email,
        otp: authCode,
        audience: getEnvVar('VITE_AUTH0_AUDIENCE'),
        realm: 'email',
        scope: 'openid profile email offline_access',
      }),
    }
  );

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(
      `Token exchange failed: ${errorResponse.error_description}`
    );
  }

  const res = await response.json();
  const userInfo = decodeIdToken(res.id_token);

  return {
    refresh_token: res.refresh_token,
    access_token: res.access_token,
    expires_at: Date.now() + res.expires_in * 1000,
    email: userInfo.email,
  };
}

export async function sync_user(access_token: string) {
  const response = await getAxiosInstance().post(
    '/sync/user',
    {},
    {
      // AS THIS RUNS INITIALLY WHEN LOGGING IN, ACCESS TOKEN IS NOT YET SET, SET TOKEN MANUALLY
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
    }
  );
  return response.data;
}

export async function get_user_data(): Promise<User> {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await getAxiosInstance().get('/get_user_data', {
    headers: { TZ: timezone },
  });
  return response.data;
}

export async function update_user_data(user: UserUpdate) {
  await getAxiosInstance().post('/update_user_data', user);
}

export async function delete_user_context() {
  await getAxiosInstance().post('/delete_user_context');
}

export async function get_todos(): Promise<Todo[]> {
  const response = await getAxiosInstance().get('/todos');
  return response.data;
}

export async function create_todo(todo: TodoCreate) {
  await getAxiosInstance().post('/todos', todo);
}

export async function update_todo(patch: TodoUpdate) {
  const updateDict: Record<string, any> = {};
  if (patch.date) {
    updateDict['date'] = patch.date;
  }
  if (patch.description !== undefined) {
    updateDict['description'] = patch.description;
  }
  if (patch.status) {
    updateDict['status'] = patch.status;
  }
  if (patch.categories !== null && patch.categories !== undefined) {
    updateDict['categories'] = patch.categories;
  }
  await getAxiosInstance().put(`/todos/${patch.id}`, updateDict);
}

export async function reorder_todo(todoId: number, newOrder: number) {
  await getAxiosInstance().patch(`/todos/reorder/${todoId}`, {
    new_order: newOrder,
  });
}

export async function get_sources(todo_id: number): Promise<Source[]> {
  const response = await getAxiosInstance().get(`/sources/${todo_id}`);
  return response.data.sources;
}

export async function get_daily_summaries(): Promise<Summary[]> {
  const response = await getAxiosInstance().get('/get_daily_summaries');
  return response.data.daily_summaries;
}

export async function get_chat_history(): Promise<Chat[]> {
  const response = await getAxiosInstance().get('/chat_history');
  return response.data.chats;
}

export async function get_shared_chat(
  chatId: string,
  chatPublicToken: string
): Promise<Chat> {
  const response = await getAxiosInstance().get(`/get_shared_chat/${chatId}`, {
    params: { public_token: chatPublicToken },
  });
  return response.data;
}

export async function update_chat(patch: ChatUpdate) {
  await getAxiosInstance().put(
    `/chat/${patch.chat_id}?title=${encodeURIComponent(patch.title || '')}`
  );
}

export async function delete_chat(chatId: number) {
  await getAxiosInstance().delete(`/chat/${chatId}`);
}

export async function set_chat_as_public(
  chatId: number
): Promise<{ public_token: string }> {
  const response = await getAxiosInstance().patch(
    `/set_chat_as_public/${chatId}`
  );
  return response.data;
}

export async function get_chat_votes(chat_id: number): Promise<VoteData[]> {
  const response = await getAxiosInstance().get(`/get_votes/${chat_id}`);
  return response.data.votes;
}

export async function post_vote(
  chat_id: number,
  message_index: number,
  direction: VoteDataDirection
) {
  await getAxiosInstance().post('/vote', {
    chat_id,
    message_index,
    direction,
  });
}

export async function create_new_chat(): Promise<number> {
  const response = await getAxiosInstance().post('/new_chat');
  return response.data.chat_id;
}

export async function get_chat_reply(
  question: string,
  chat_id: number,
  assistant: Assistant,
  chatMode: ChatMode,
  attachments: string[],
  onNewEvent: (data: any) => void
) {
  const formData = new FormData();
  formData.append('question', question);
  formData.append('chat_id', chat_id.toString());
  formData.append('model', assistant);
  formData.append('mode', chatMode);

  const version = chatMode === ChatMode.fast ? 'v3' : 'v4';

  if (attachments) {
    try {
      attachments = Array.isArray(attachments) ? attachments : [attachments];
      for (let i = 0; i < attachments.length; i++) {
        const file = await getFileFromPath(attachments[i]);
        formData.append('files', file);
      }
    } catch (error) {
      console.error('Failed to read attached file(s) for chat', error);
    }
  }

  try {
    const accessToken = getLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);

    await fetchEventSource(`${API_URL}/chat_${version}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      openWhenHidden: true,
      onmessage(msg) {
        try {
          const response = JSON.parse(msg.data);
          onNewEvent(response);
        } catch (_error) {
          console.error('Error parsing SSE message:', msg.data);
        }
      },
      async onopen(response) {
        if (
          response.ok &&
          response.headers.get('content-type')?.includes('text/event-stream')
        ) {
          return;
        }
        throw new Error(
          `Failed to connect: ${response.status} ${response.statusText}`
        );
      },
      onerror(err) {
        console.error('SSE error:', err);
        throw err;
      },
    });
  } catch (e) {
    console.error('Error fetching chat reply:', e);
    throw e;
  }
}

export async function get_autocomplete(
  question: string,
  chat_id: number
): Promise<string[]> {
  const response = await getAxiosInstance().post('/autocomplete', {
    question,
    chat_id,
  });
  return response.data.autocompletes;
}

export async function get_recommended_questions(): Promise<string[]> {
  const response = await getAxiosInstance().get('/recommended_questions');
  return response.data.recommended_questions;
}

export async function get_categories(): Promise<any[]> {
  const response = await getAxiosInstance().get('/get_categories');
  return response.data.categories;
}

export async function edit_category(
  id?: number,
  name?: string,
  capture?: string,
  ignore?: string
) {
  const response = await getAxiosInstance().post('/edit_category', {
    name,
    id,
    capture,
    ignore,
  });
  return response.data.category;
}

export async function remove_category(categoryId: number) {
  await getAxiosInstance().delete(`/remove_category/${categoryId}`);
}

export async function stop_chat_with_context(
  chatId: number,
  last_message?: string[]
) {
  await getAxiosInstance().post(`/stop_chat_with_context/${chatId}`, {
    last_message,
  });
}

export async function speech_to_text(audio: Blob): Promise<string> {
  const formData = new FormData();
  formData.append('file', audio, 'recording.wav');

  const response = await getAxiosInstance().post('/stt', formData);
  return response.data.text;
}

export async function text_to_speech(text: string): Promise<string> {
  const response = await getAxiosInstance().post(
    '/tts',
    { text },
    { responseType: 'blob' }
  );

  const blob = new Blob([response.data], { type: 'audio/mpeg' });
  return URL.createObjectURL(blob);
}

export async function delete_inputs(timestamp: number) {
  await getAxiosInstance().post('/delete/inputs', { timestamp });
}

export async function get_personal_access_token(): Promise<string> {
  const response = await getAxiosInstance().get('/personal_access_token');
  return response.data.access_token;
}

export async function get_message_suggestions(
  app: string,
  context?: string,
  focusedInputDescription?: string,
  focusedInputX?: number,
  focusedInputY?: number,
  windowHeight?: number,
  windowWidth?: number
): Promise<string[]> {
  const response = await getAxiosInstance().post(
    '/generate_hummingbird_suggestions',
    {
      app,
      current_context: context,
      focused_input_description: focusedInputDescription,
      focused_input_x: focusedInputX,
      focused_input_y: focusedInputY,
      window_height: windowHeight,
      window_width: windowWidth,
    }
  );
  return response.data.suggestions;
}

export async function generate_hummingbird_todo(
  app: string,
  currentContext: string
): Promise<{
  todo: Todo;
}> {
  const response = await getAxiosInstance().post('/generate_hummingbird_todo', {
    app,
    current_context: currentContext,
  });
  return response.data;
}
