import { createFileRoute, redirect } from '@tanstack/react-router';

import { checkAccessibilityPermissionsQueryOptions } from '@/query/permissions';
import { queryClient } from '@/stores/jotaiStore';

export const Route = createFileRoute('/grant-permissions')({
  beforeLoad: async () => {
    const hasAccessibilityPermissions = await queryClient.ensureQueryData(
      checkAccessibilityPermissionsQueryOptions()
    );

    if (hasAccessibilityPermissions) throw redirect({ to: '/', replace: true });
  },
});
