import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { Transition } from '@headlessui/react';
import { useEffect } from 'react';

import { useAppStore } from '../../store';
import IconButton from '../ui/IconButton';

const Toast = () => {
  const { toastData, setToastData } = useAppStore((state) => state);

  useEffect(() => {
    const id = setTimeout(() => {
      setToastData(null);
    }, 5000);

    return () => clearTimeout(id);
  }, [toastData, setToastData]);

  return (
    <div className="relative">
      <Transition
        show={toastData !== null}
        enter="transform ease-out duration-300"
        enterFrom="translate-y-8 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition-opacity ease-in duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed overflow-clip top-4 right-4 max-w-xs p-4 w-full rounded  backdrop-blur-lg shadow-card border border-darkLine bg-darkField z-50">
          <div className="flex flex-row gap-2">
            <div className="flex-1">
              <div
                className={`flex flex-grow ${
                  toastData?.kind === 'info'
                    ? 'text-darkPrimary'
                    : 'text-darkRed'
                } mb-2 text-sm`}
              >
                {toastData?.title}
              </div>
              <div className="flex flex-grow w-full text-darkSecondary text-sm select-text cursor-text">
                {toastData?.description}
              </div>
            </div>
            <IconButton
              className="self-start"
              icon={faClose}
              onClick={() => {
                setToastData(null);
              }}
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Toast;
