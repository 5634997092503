import { queryOptions } from '@tanstack/react-query';

import { getAxiosInstance } from '@/utils/axios';

export type OnboardingSchema = {
  onboarding_progress: number;
};

export const onboardingQueryOptions = () =>
  queryOptions({
    queryKey: ['onboarding'],
    queryFn: async () => {
      const response = await getAxiosInstance().get('/onboarding_progress');
      return response.data as OnboardingSchema;
    },
  });

export const onboardingMutationOptions = () => ({
  mutationKey: ['update-onboarding'],
  mutationFn: async (data: Partial<OnboardingSchema>) => {
    await getAxiosInstance().post('/onboarding_progress', data);
  },
});
