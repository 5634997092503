import axios, { AxiosRequestConfig } from 'axios';
import posthog from 'posthog-js';
import * as R from 'remeda';

import { API_URL, useAppStore } from '../store';

import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_EXPIRES_AT_LOCAL_STORAGE_KEY,
  logoutUser,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  refreshAccessToken,
} from './auth';
import { getLocalStorageItem, setLocalStorageItem } from './localStorage';

import { router } from '@/router';

const minimumUserAuthTokensRefreshTrackingGapMs = 1000 * 60 * 5;

const trackUserAuthTokensWillRefresh = R.funnel(
  () => posthog.capture('user_auth_tokens_will_refresh'),
  { minGapMs: minimumUserAuthTokensRefreshTrackingGapMs, triggerAt: 'start' }
);

const trackUserAuthTokensDidRefresh = R.funnel(
  () => posthog.capture('user_auth_tokens_did_refresh'),
  { minGapMs: minimumUserAuthTokensRefreshTrackingGapMs, triggerAt: 'start' }
);

const isTokenNearExpiry = (thresholdInMilliSeconds = 20000): boolean => {
  try {
    const expiresAt = getLocalStorageItem(
      ACCESS_TOKEN_EXPIRES_AT_LOCAL_STORAGE_KEY
    );
    return expiresAt - Date.now() < thresholdInMilliSeconds;
  } catch {
    return true; // Treat token as near expiry
  }
};

let refreshFailed = false;
let refreshInProgress = false;
let refreshSubscribers: ((token: string | null) => void)[] = [];

const refreshAuthToken = async (): Promise<string> => {
  if (refreshInProgress) {
    return new Promise((resolve, reject) => {
      refreshSubscribers.push((newToken) => {
        if (newToken) {
          resolve(newToken);
        } else {
          reject(new Error('Token refresh failed'));
        }
      });
    });
  }

  refreshInProgress = true;

  try {
    const refreshToken = getLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    if (!refreshToken) {
      throw new Error('No refresh token found');
    }

    const response = await refreshAccessToken(refreshToken);
    const { access_token, expires_at, refresh_token } = response;

    setLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);
    setLocalStorageItem(ACCESS_TOKEN_EXPIRES_AT_LOCAL_STORAGE_KEY, expires_at);
    setLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token);

    refreshFailed = false;

    refreshSubscribers.forEach((callback) => callback(access_token));
    refreshSubscribers = [];

    trackUserAuthTokensDidRefresh.call();

    return access_token;
  } catch (error) {
    refreshFailed = true;

    refreshSubscribers.forEach((callback) => callback(null));
    refreshSubscribers = [];

    posthog.capture('user_logged_out', {
      logout_type: 'token_expiry',
    });

    logoutUser(false);
    router.navigate({ to: '/error/session-expired' });

    throw error;
  } finally {
    refreshInProgress = false;
  }
};

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(
    async (request) => {
      const accessToken = getLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
      if (accessToken) {
        if (isTokenNearExpiry()) {
          trackUserAuthTokensWillRefresh.call();

          await refreshAuthToken();
          const newAccessToken = getLocalStorageItem(
            ACCESS_TOKEN_LOCAL_STORAGE_KEY
          );
          request.headers['Authorization'] = `Bearer ${newAccessToken}`;
        } else {
          request.headers['Authorization'] = `Bearer ${accessToken}`;
        }
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => {
      if (response?.status !== 200) {
        throw new Error(
          response?.data?.message ||
            `Request failed with status ${response?.status}`
        );
      }
      return response;
    },
    async (error) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;

      if (!error.response) {
        if (!refreshFailed) {
          router.invalidate();
          router.navigate({ to: '/error/failed-to-connect' });
        }
        return Promise.reject({
          message: 'Network error - unable to connect to server',
        });
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!refreshFailed) {
          await refreshAuthToken();

          const newToken = getLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: `Bearer ${newToken}`,
          };
          return instance(originalRequest);
        }
      }

      const message = error.response?.data?.detail?.user_error_message;

      if (message) {
        useAppStore.getState().setToastData({
          title: 'Error',
          description: message,
          kind: 'error',
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const getAxiosInstance = () => createAxiosInstance(API_URL);
