import { atom } from 'jotai';

import { getLocalStorageItem } from '@/utils/localStorage';

const ACCESS_TOKEN = 'access_token';
const EXPIRES_AT = 'expires_at';
const REFRESH_TOKEN = 'refresh_token';

const accessTokenAtom = atom(() => getLocalStorageItem(ACCESS_TOKEN));
const expiresAtAtom = atom(() => getLocalStorageItem(EXPIRES_AT));

const refreshTokenAtom = atom(() => getLocalStorageItem(REFRESH_TOKEN));

export const authTokensAtom = atom((get) => ({
  accessToken: get(accessTokenAtom),
  expiresAt: get(expiresAtAtom),
  refreshToken: get(refreshTokenAtom),
}));
