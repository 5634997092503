import './global.css';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import env, { getEnvVar } from './env';
import { RouterProvider } from './providers/RouterProvider';
import { UpdateProvider } from './providers/UpdateProvider';
import jotaiStore, { queryClient } from './stores/jotaiStore';

import { setupLogger } from '@/utils/logger';

const isDev = getEnvVar('VITE_ENV') === 'development';
const isRunningInLocalhost = getEnvVar('IS_RUNNING_IN_LOCALHOST');

// TODO: Add environment tag while initializing posthog
if (!isDev && !isRunningInLocalhost) {
  posthog.init(getEnvVar('VITE_POSTHOG_API_KEY'), {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always',
    capture_pageview: false,
  });
}

// TODO: Add environment tag while initializing sentry
Sentry.init({
  dsn: 'https://96f904e733472b528b0154cd44021a41@o4507576431542272.ingest.us.sentry.io/4508289503789056',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !isDev,
});

// tauri logging setup
setupLogger();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={jotaiStore}>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <UpdateProvider>
            <RouterProvider />
          </UpdateProvider>
        </QueryClientProvider>
      </PostHogProvider>
    </Provider>
  </React.StrictMode>
);
