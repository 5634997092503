import * as path from '@tauri-apps/api/path';
import { readFile } from '@tauri-apps/plugin-fs';

export async function getFileFromPath(filePath: string): Promise<File> {
  // try {

  // Handle blob URLs from clipboard
  if (filePath.startsWith('blob:')) {
    const response = await fetch(filePath);
    const blob = await response.blob();
    return new File([blob], 'clipboard-image.png', {
      type: 'image/png',
    });
  }

  // Read the file content
  const binaryData = await readFile(filePath);

  // Get filename and extension
  const fileName = await path.basename(filePath);
  const fileExt = await path.extname(filePath);

  // Create the File object
  const blob = new Blob([binaryData]);
  const file = new File([blob], fileName, {
    type: getMimeType(fileExt),
  });

  return file;
  // } catch (error) {
  //   throw new Error(`Failed to get file info: ${error}`);
  // }
}

export async function validateFile(
  filePath: string,
  options?: {
    maxSize?: number;
    allowedTypes?: string[];
  }
): Promise<boolean> {
  try {
    const fileInfo = await getFileFromPath(filePath);

    if (options?.maxSize && fileInfo.size > options.maxSize) {
      throw new Error(
        `File size exceeds maximum allowed size of ${options.maxSize} bytes`
      );
    }

    if (options?.allowedTypes && options.allowedTypes.length > 0) {
      const fileType = getMimeType(await path.extname(filePath));
      if (!options.allowedTypes.includes(fileType)) {
        throw new Error(`File type ${fileType} is not allowed`);
      }
    }

    return true;
  } catch (error) {
    throw new Error(`File validation failed: ${error}`);
  }
}
const mimeTypes: Record<string, string> = {
  // Images
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',

  // Documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  // Text
  txt: 'text/plain',
  csv: 'text/csv',
  md: 'text/markdown',
  json: 'application/json',

  // Archives
  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
  '7z': 'application/x-7z-compressed',
};

function getMimeType(extension: string): string {
  const ext = extension.toLowerCase().replace('.', '');
  return mimeTypes[ext];
}
