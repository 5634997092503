import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';

export default function IconButton({
  icon,
  onClick,
  hoverIcon,
  size = '15px',
  disabled = false,
  className = '',
  iconProps,
  style = {},
}: {
  icon: IconDefinition;
  onClick: MouseEventHandler;
  hoverIcon?: IconDefinition;
  size?: string;
  disabled?: boolean;
  className?: string;
  iconProps?: Partial<FontAwesomeIconProps>;
  style?: React.CSSProperties;
}) {
  return (
    <button
      className={`disabled:opacity-80 group ${className}`} // Add 'group' to enable hover targeting
      onClick={onClick ?? undefined}
      disabled={disabled}
      style={style}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{ fontSize: size }}
        className={`
          duration-75 
          transition-colors 
          text-darkIcons 
          group-hover:text-darkPrimary 
          ${hoverIcon && 'group-hover:hidden'}
          ${iconProps?.className ?? ''}
        `.trim()}
        {...iconProps}
      />
      {hoverIcon && (
        <FontAwesomeIcon
          icon={hoverIcon}
          style={{ fontSize: size }}
          className={`
            transition-colors
            text-darkIcons
            group-hover:text-darkPrimary
            hidden group-hover:block
            ${iconProps?.className ?? ''}
          `.trim()}
          {...iconProps}
        />
      )}
    </button>
  );
}
