import { atom } from 'jotai';
import { RESET } from 'jotai/utils';
import { atomWithMutation, atomWithQuery } from 'jotai-tanstack-query';

import { queryClient } from '../jotaiStore';

import { userQueryOptions, userMutationOptions, User } from '@/query/user';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage';

const userQueryAtom = atomWithQuery(() => userQueryOptions());

const userMutationAtom = atomWithMutation(() => userMutationOptions());

export const userAtom = atom(
  (get) => {
    const { data } = get(userQueryAtom);

    if (data && !getLocalStorageItem('user')) {
      setLocalStorageItem('user', data);

      return data;
    }

    return { ...getLocalStorageItem('user'), ...data };
  },
  (get, _set, value: Partial<User> | typeof RESET) => {
    const { queryKey } = userQueryOptions();

    if (value === RESET) {
      localStorage.removeItem('user');

      queryClient.removeQueries({ queryKey });

      return;
    }

    setLocalStorageItem('user', value);

    queryClient.setQueryData(queryKey, value as User);

    get(userMutationAtom).mutate(value, {
      onSuccess: () => get(userQueryAtom).refetch(),
    });
  }
);
