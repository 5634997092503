import { RESET } from 'jotai/utils';
import posthog from 'posthog-js';
import { z } from 'zod';

import { getLocalStorageItem } from './localStorage';
import { inDesktop, inMobile } from './platform';

import { getEnvVar } from '@/env';
import { router } from '@/router';
import { useAppStore } from '@/store';
import { userAtom } from '@/stores/atoms/user';
import jotaiStore from '@/stores/jotaiStore';

export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token';
export const ACCESS_TOKEN_EXPIRES_AT_LOCAL_STORAGE_KEY = 'expires_at';
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refresh_token';

const AuthTokensResponseSchema = z.object({
  access_token: z.string(),
  expires_at: z.number(),
  refresh_token: z.string(),
});

export async function logoutUser(redirect = true) {
  try {
    await logout();

    jotaiStore.set(userAtom, RESET);

    useAppStore.getState().reset();

    posthog.reset();

    if (!redirect) return;

    if (inDesktop() || inMobile()) {
      router.navigate({ to: '/login' });

      return;
    }

    const redirectUri = encodeURIComponent(window.location.origin + '/login');

    window.location.href = `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/logout?client_id=${getEnvVar('VITE_AUTH0_CLIENT_ID')}&returnTo=${redirectUri}`;
  } catch (error) {
    useAppStore.getState().setToastData({
      title: 'Error',
      description: (error as Error)?.message,
      kind: 'error',
    });
  }
}

export async function refreshAccessToken(refreshToken: string) {
  const response = await fetch(
    `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/oauth/token`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        client_id: getEnvVar('VITE_AUTH0_CLIENT_ID'),
        refresh_token: refreshToken,
      }),
    }
  );

  if (!response.ok) {
    throw new Error('Failed to refresh access token');
  }

  const res = await response.json();

  return AuthTokensResponseSchema.parse({
    ...res,
    expires_at: Date.now() + res.expires_in * 1000,
  });
}

export function isAuthenticated() {
  return !!localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
}

export function resetAuthTokens() {
  localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  localStorage.removeItem(ACCESS_TOKEN_EXPIRES_AT_LOCAL_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
}

export function decodeIdToken(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(atob(base64));
}

async function logout() {
  revokeRefreshToken(getLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY));

  resetAuthTokens();
}

async function revokeRefreshToken(token: string) {
  const response = await fetch(
    `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/oauth/revoke`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        client_id: getEnvVar('VITE_AUTH0_CLIENT_ID'),
      }),
    }
  );

  if (!response.ok) {
    throw new Error('Failed to revoke refresh token');
  }
}
