import { atom } from 'jotai';
import { RESET } from 'jotai/utils';
import { atomWithMutation, atomWithQuery } from 'jotai-tanstack-query';

import { queryClient } from '../jotaiStore';

import {
  OnboardingSchema,
  onboardingMutationOptions,
  onboardingQueryOptions,
} from '@/query/onboarding';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage';

export const ONBOARDING_STARTED: number = 0; // Unused, for reference only
// Can add more steps to the initial stage, therefore 0.0 until 0.2 can be considered as initial steps
export const STEP_INITIAL_COMPLETE: number = 0.2;

export const STEP_TASKS_STARTED: number = 0.2;
export const STEP_TASKS_ADD_TODO: number = 0.4;
export const STEP_TASKS_MARK_DONE: number = 0.5;
export const STEP_TASKS_COMPLETE: number = 0.6;

export const STEP_JOURNAL_STARTED: number = 0.6;
export const STEP_JOURNAL_IN_PROGRESS: number = 0.7;
export const STEP_JOURNAL_COMPLETE: number = 0.8;

export const ONBOARDING_COMPLETE: number = 1; // Unused, for reference only

const onboardingQueryAtom = atomWithQuery(() => onboardingQueryOptions());
const onboardingMutationAtom = atomWithMutation(() =>
  onboardingMutationOptions()
);

export const onboardingAtom = atom(
  (get) => {
    const { data } = get(onboardingQueryAtom);

    if (data && !getLocalStorageItem('onboarding')) {
      setLocalStorageItem('onboarding', data);
    }

    return {
      ...getLocalStorageItem('onboarding'),
      ...data,
    } as OnboardingSchema;
  },
  (get, _set, value: Partial<OnboardingSchema> | typeof RESET) => {
    const { queryKey } = onboardingQueryOptions();

    if (value === RESET) {
      localStorage.removeItem('onboarding');
      queryClient.removeQueries({ queryKey });
      return;
    }

    setLocalStorageItem('onboarding', value);

    queryClient.setQueryData(queryKey, value as OnboardingSchema);

    get(onboardingMutationAtom).mutate(value, {
      onSuccess: () => get(onboardingQueryAtom).refetch(),
    });
  }
);
