export type Source = {
  text: string;
  app: string;
};

export enum TodoStatus {
  suggestion = 'suggestion',
  accepted = 'accepted',
  rejected = 'rejected',
  completed = 'completed',
  deleted = 'deleted',
}

// The backend used to return only description.
// The title is about one sentence long.
// The description is about 4 sentences long.
export type Todo = {
  order: number;
  id: number;
  title: string;
  description: string;
  status: TodoStatus;
  date: string;
  categories: string[];
};

export type TodoCreate = {
  title: string;
  description: string;
  status: TodoStatus;
  date: string;
  categories: string[];
};

export type TodoUpdate = {
  id: number;
  description: string;
  status: TodoStatus;
  date: string;
  categories: string[];
};

export type User = {
  global_prompt: string;
  email: string;
  latest_input: string;
  total_inputs: string;
  todo_prompt: string;
  summary_prompt: string;
  info_to_ignore_prompt: string;
  name: string;
  aliases: string;
};

export type UserUpdate = {
  global_prompt?: string;
  email?: string;
};

export type Summary = {
  date: string;
  text: string;
};

export type Chat = {
  title: string;
  chat_id: number;
  chat_history: string[][];
  date: string;
};

export type ChatUpdate = {
  chat_id: number;
  title: string;
};

export type PartialAnswers = {
  [chatId: number]: {
    partialAnswer: string;
    isThinking: boolean;
    messageIndex: number;
  };
};

export type VoteDataDirection = -1 | 1;

export type VoteData = {
  message_index: number;
  direction: VoteDataDirection;
};

export type Category = {
  id: number;
  name: string;
  capture: string | null;
  ignore: string | null;
};

export enum Assistant {
  claude = 'claude',
  gpt = 'gpt',
}

export enum ChatMode {
  agentic = 'agentic',
  smart = 'smart',
  fast = 'fast',
  experimental = 'experimental',
}

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

export interface WindowInfo {
  title: string;
  position: Position;
  size: Size;
}

export interface ActiveApp {
  name: string;
  pid: number;
  iconBase64: string | null;
  windows: string[];
}

interface Frame {
  position: Position;
  size: Size;
}

export interface FocusedElement {
  role: string;
  description: string;
  value?: string;
  frame?: Frame;
  selection?: string;
}

export interface ChatEdit {
  chat_id: number;
  new_title: string;
}
